import styled from 'styled-components';

const TimelineStyles = styled.div`
  /* Timeline Styling */
  .timeline {
    position: relative;
    margin-top: 20px;
  }

  .timeline::after {
    content: '';
    position: absolute;
    width: 15px;
    background-color: #363636;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -5px;
  }

  .timeline-item {
    padding: 0px 40px 20px 40px;
    position: relative;
    width: 50%;
  }

  .timeline-item::after {
    content: attr(data-year);
    position: absolute;
    width: 25px;
    height: 25px;
    right: -15px;
    background-color: var(--gray-2);
    border: 1px solid var(--gray-1);
    top: 20px;
    border-radius: 50%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 13px;
    color: var(--gray-1);
  }

  .left {
    left: 0;
  }

  .right {
    left: 50%;
  }

  .left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid black;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent #BCB4B4;
  }

  .right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid black;
    border-width: 10px 10px 10px 0;
    border-color: transparent #BCB4B4 transparent transparent;
  }

  .right::after {
    left: -10px;
  }

  .timeline-card {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;
  }

  .card-header h5 {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .card-header h6 {
    font-size: 16px;
  }

  .card-body {
    margin-top: 15px;
  }

  .card-footer h6 {
    font-size: 16px;
    margin-top: 10px;
  }

  .card {
    background-color: #363636;
  }

  i {
    padding: 5px;
  }

  .btn {
    margin: 5px;
  }

  a {
    color: black;
  }

  .main {
    margin: auto;
    padding-top: 100px;
  }

  .center {
    margin: auto;
    width: 100%;
    text-align: center;
  }

  .padding {
    margin: auto;
    width: 80%;
  }

  .timeline-card .accordion-header > button {
    background-color: var(--highlight-blue);
    width: 100%;
  }

  .accordion-fab {
    margin: auto; 
  }

  /* Responsive Styles */
  @media (max-width: 768px) {
    .timeline-item {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }

    .timeline-item::after {
      display: none;
    }

    .left, .right {
      left: 0;
      text-align: left;
    }

    .timeline-item::before, .timeline-item::after {
      border-width: 8px;
      display: none;
    }

    .timeline-card {
      padding: 15px;
    }

    .card-header h5 {
      font-size: 18px;
    }

    .card-header h6 {
      font-size: 14px;
    }

    .card-body, .card-footer {
      font-size: 14px;
    }

    /* Move the timeline center line to the left on smaller screens */
    .timeline::after {
    //   left: 0px;
      display: none; 
    }
  }

  }
`;

export default TimelineStyles;
