import React from 'react';
import styled from 'styled-components';
import MapImg from '../assets/images/toronto.jpeg';

const MapStyles = styled.div`
  background: url(${MapImg}) no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 400px;
  position: relative;

  .container {
    position: relative;
    min-height: 400px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 2rem;
  }

  .map__card {
    padding: 2rem;
    background: var(--deep-dark);
    color: var(--gray-1);
    width: 100%;
    max-width: 300px;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }

  .map__card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }

  .map__card__heading {
    font-size: 2.4rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }

  .map__card__link {
    display: inline-block;
    font-size: 1.6rem;
    margin-top: 2rem;
    text-decoration: underline;
    color: var(--highlight-blue);
    transition: color 0.3s;
  }

  .map__card__link:hover {
    color: var(--gray-1);
  }

  @media only screen and (max-width: 768px) {
    background-position: 80% center;

    .map__card {
      max-width: none;
      width: 100%;
      border-radius: 8px;
    }
  }

  @media only screen and (max-width: 400px) {
    .map__card {
      padding: 1.5rem;
    }

    .map__card__heading {
      font-size: 2rem;
    }

    .map__card__link {
      font-size: 1.4rem;
    }
  }
`;

export default function Map() {
  return (
    <MapStyles>
      <div className="container">
        <div className="map__card" aria-label="Location Card">
          <h3 className="map__card__heading">I am in ...</h3>
          <h1>Toronto, Canada</h1>
          <a
            className="map__card__link"
            href="https://www.google.com/maps/place/Toronto,+ON/@43.717899,-79.6582408,10z/data=!3m1!4b1!4m5!3m4!1s0x89d4cb90d7c63ba5:0x323555502ab4c477!8m2!3d43.653226!4d-79.3831843?hl=en-GB"
            target="_blank"
            rel="noopener noreferrer"
          >
            Open in Google Maps
          </a>
        </div>
      </div>
    </MapStyles>
  );
}
