import React from 'react';
import styled from 'styled-components';
import { MdEmail, MdLocalPhone, MdMap } from 'react-icons/md';
import ContactForm from './ContactForm';
import ContactInfoItem from './ContactInfoItem';
import SectionTitle from './SectionTitle';

const ContactPageStyles = styled.div`
  padding: 10rem 0;
  .contactSection__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    align-items: start;
    margin-top: 5rem;
  }
  .left,
  .right {
    background: var(--deep-dark);
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  .contactInfo {
    display: flex;
    flex-direction: column;
    gap: 4.6rem;
  }
  @media only screen and (max-width: 768px) {
    .contactSection__wrapper {
      grid-template-columns: 1fr;
    }
  }
`;

export default function ContactSection() {
  return (
    <ContactPageStyles>
      <div className="container">
        <SectionTitle heading="Contact Us" subheading="We'd love to hear from you" />
        <div className="contactSection__wrapper">
          <div className="left">
            <div className="contactInfo">
              <ContactInfoItem icon={<MdLocalPhone />} text="+1-647-581-7770" />
              <ContactInfoItem icon={<MdEmail />} text="ianasqazi@gmail.com" />
              <ContactInfoItem icon={<MdMap />} text="Toronto, Canada" />
            </div>
          </div>
          <div className="right">
            <ContactForm />
          </div>
        </div>
      </div>
    </ContactPageStyles>
  );
}
