import React, { Suspense } from 'react';

// Lazy load components
// const AboutSection = React.lazy(() => import('../components/AboutSection'));
// const TestimonialsSection = React.lazy(() => import('../components/TestimonialsSection'));
const ServicesSection = React.lazy(() => import('../components/ServicesSection'));
const ProjectsSection = React.lazy(() => import('../components/ProjectsSection'));
const ContactBanner = React.lazy(() => import('../components/ContactBanner'));
const HeroSection = React.lazy(() => import('../components/HeroSection'));

export default function Home() {
  return (
    <div>
      {/* Wrap components that are lazy-loaded with Suspense */}
      <Suspense fallback={<div>Loading...</div>}>
        <HeroSection />
      </Suspense>
      {/* Lazy-load AboutSection */}
      {/* <Suspense fallback={<div>Loading About...</div>}>
        <AboutSection />
      </Suspense> */}
      {/* Lazy-load ServicesSection */}
      <Suspense fallback={<div>Loading Services...</div>}>
        <ServicesSection />
      </Suspense>
      {/* Lazy-load ProjectsSection */}
      <Suspense fallback={<div>Loading Projects...</div>}>
        <ProjectsSection />
      </Suspense>
      {/* Lazy-load TestimonialsSection */}
      {/* <Suspense fallback={<div>Loading Testimonials...</div>}>
        <TestimonialsSection />
      </Suspense> */}
      {/* Lazy-load ContactBanner */}
      <Suspense fallback={<div>Loading Contact...</div>}>
        <ContactBanner />
      </Suspense>
    </div>
  );
}
