import { createGlobalStyle } from 'styled-components';
import RobotoMonoRegular from '../assets/fonts/RobotoMono-Regular.ttf';
import MontserratSemiBold from '../assets/fonts/Montserrat-SemiBold.ttf';
import MontserratBold from '../assets/fonts/Montserrat-Bold.ttf';

// Preload fonts for faster loading
const Typography = createGlobalStyle`
  /* Preload fonts to improve performance */
  @font-face {
    font-family: 'RobotoMono Regular';
    src: url(${RobotoMonoRegular}) format('truetype');
    font-style: normal;
    font-display: swap; /* This helps to avoid FOIT (Flash of Invisible Text) */
  }

  @font-face {
    font-family: 'Montserrat SemiBold';
    src: url(${MontserratSemiBold}) format('truetype');
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Montserrat Bold';
    src: url(${MontserratBold}) format('truetype');
    font-style: normal;
    font-display: swap;
  }

  /* Preload Fonts */
  link[rel="preload"] {
    font-family: 'RobotoMono Regular', 'Montserrat SemiBold', 'Montserrat Bold';
    font-weight: normal;
    font-style: normal;
  }

  html {
    font-family: 'RobotoMono Regular', sans-serif;  /* Default font for the page */
    color: var(--gray-1);
  }

  /* Apply RobotoMono Regular as the default font */
  body {
    font-family: 'RobotoMono Regular', sans-serif;
    color: var(--gray-1);
  }

  /* Override font for headings */
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat SemiBold', sans-serif;
  }

  /* Apply 'Montserrat Bold' to stronger emphasis or any other necessary components */
  strong, b {
    font-family: 'Montserrat Bold', sans-serif;
  }
`;

export default Typography;
