import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Footer from './components/Footer';
import NavMenu from './components/NavMenu';
import ScrollToTop from './components/ScrollToTop';
import About from './pages/About';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Resume from './pages/Resume';
import PageNotFound from './pages/PageNotFound'
import './App.css';

const routes = [
  { path: "/about", component: About },
  { path: "/contact", component: Contact },
  { path: "/resume", component: Resume },
  { path: "/projects", component: Projects },
  { path: "/", component: Home, exact: true },
];

export default function App() {
  return (
    <Router>
      <NavMenu />
      <ScrollToTop />
      <Switch>
        {routes.map(({ path, component, exact = false }) => (
          <Route key={path} path={path} component={component} exact={exact} />
        ))}
        <Route component={PageNotFound} /> {/* 404 Route placed last */}
      </Switch>
      <Footer />
    </Router>
  );
}
