// Timeline.js

import React, { useState, useMemo } from 'react';
import { Accordion, Card } from 'react-bootstrap'; // Import necessary components from react-bootstrap

import workExperience from '../assets/data/workExperience';
import TimelineStyles from './TimelineStyles'; // Import the styles from the separate file

import { FaAngleDoubleUp, FaAngleDoubleDown } from "react-icons/fa";

const TimelineItem = React.memo(({ experience, index }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Accordion.Item eventKey={String(index)} key={index}>
      <div
        className={`timelineContainer timeline-item ${
          index % 2 === 0 ? 'left' : 'right'
        }`}
      >
        <Card className="timeline-card card">
          <Accordion.Header onClick={handleToggle}>
            <div className='row'>
            <div className='col-10'>
              <div className="card-header">
                <h4>{experience.company}</h4>
                <h5>{experience.role}</h5>
                <h6>{experience.date}</h6>
              </div>
            </div>
            <div className='col-2 accordion-fab'>
              {/* FAB Icon for open/close state */}
              <div className="fab-icon">
                {isOpen ? <FaAngleDoubleUp /> : <FaAngleDoubleDown />}
              </div>
            </div>

            </div>
            
            
          </Accordion.Header>
          <Accordion.Body>
            <div className="card-body">
              {experience.project && experience.project.length > 0 && (
                <h6>Project: {experience.project}</h6>
              )}
              <h6>Responsibilities:</h6>
              <ul>
                {experience.responsibilities && experience.responsibilities.length > 0
                  ? experience.responsibilities.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))
                  : <li>No responsibilities listed</li>}
              </ul>
            </div>
            {experience.technologies && experience.technologies.length > 0 && (
              <div className="card-footer">
                <h6>Technologies Used:</h6>
                <p>{experience.technologies.join(', ')}</p>
              </div>
            )}
          </Accordion.Body>
        </Card>
      </div>
    </Accordion.Item>
  );
});

export default function Timeline() {
  // UseMemo to optimize rendering of workExperience data
  const experiences = useMemo(() => workExperience, []);

  if (!experiences || experiences.length === 0) {
    return <div>No work experience data available</div>;
  }

  return (
    <TimelineStyles>
      <div className="padding" id="technical-skills-section">
        <div className="container-fluid work-experience-text">
          <div className="row">
              <div className="col">
                <h3 className="about__info__heading">WORK EXPERIENCE</h3>
              </div>
            </div>
          <div className="timeline">
            <Accordion>
              {experiences.map((experience, index) => (
                <TimelineItem key={index} experience={experience} index={index} />
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </TimelineStyles>
  );
}
