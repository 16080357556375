const workExperience = [
  {
    date: "Sept 2021 – Present",
    year: "2021-Present",
    company: "Veriday",
    role: "Software Developer",
    project: "Liferay CMS & AWS Infrastructure",
    responsibilities: [
      "• Delivered comprehensive consulting services for Liferay CMS projects, including custom portlet development, theme creation, and user-centric portal designs tailored to client needs.",
      "• Led a Liferay DXP Upgrade initiative for a key client, migrating their platform to the latest version with minimal downtime and ensuring compatibility of custom features, plugins, and third-party APIs.",
      "• Designed and developed custom Liferay portlets to add new functionalities to client portals, including interactive dashboards, data visualizations, and internal collaboration tools.",
      "• Developed dynamic web content portlets, leveraging Liferay Service Builder for custom business logic and enhancing content management features.",
      "• Built admin portlets for back-office operations, allowing clients to manage portal users, permissions, and workflows.",
      "• Managed and optimized AWS infrastructure for multiple client projects and Veriday’s internal systems.",
      "• Configured and maintained services such as EC2, S3, RDS, DynamoDB, VPC, CloudFront, Elastic Load Balancer, and CloudFormation for scalable and cost-effective solutions.",
      "• Automated deployment processes using AWS CodePipeline, CodeBuild, and CodeDeploy, reducing manual intervention and improving CI/CD efficiency.",
      "• Ensured high availability and fault tolerance by implementing CloudWatch monitoring, setting up alarms, and leveraging Auto Scaling for dynamic resource management.",
      "• Designed disaster recovery strategies using RDS snapshots and S3 backup solutions to ensure business continuity.",
      "• Integrated third-party APIs and services (e.g., payment gateways, OAuth authentication) into Liferay-based solutions, extending platform capabilities.",
      "• Collaborated with cross-functional teams, including UI/UX designers, to build WCAG-compliant, accessible, and responsive user interfaces.",
      "• Implemented DevOps best practices, leveraging Jenkins, Docker, and Kubernetes to streamline build and deployment pipelines.",
      "• Developed and maintained technical documentation using Confluence, enabling seamless knowledge transfer and client training for effective CMS use.",
      "• Optimized Liferay portal performance through query tuning, caching strategies, and Elasticsearch integration.",
      "• Regularly met with clients to gather requirements and ensure that solutions were aligned with their business objectives and end-user needs.",
      "• Utilized Jira for managing development tasks, sprint planning, and tracking project progress, ensuring efficient task prioritization and completion.",
      "• Used Bitbucket for version control, branching strategies, and collaboration on code repositories.",
      "• Contributed to Confluence pages for internal documentation, providing a knowledge repository for the team and streamlining project handovers."
    ],
    technologies: [
      "Liferay DXP", "Java", "Spring Boot", "React.js", "Angular", "Elasticsearch", "REST APIs", "SQL", 
      "Jenkins", "Docker", "Kubernetes", "AWS (EC2, RDS, S3, DynamoDB, VPC, IAM, CloudFormation, CloudWatch, Auto Scaling)", 
      "Git", "Bitbucket", "Jira", "Confluence"
    ],
    clients: [
      "Kearney", "Saskatchewan Insurance Company", "IPCC", "CIBC Innovation Banking", "CIBC Private Wealth Management",
      "Laurentian Bank", "Britannia School House", "Canadian Tire", "Entando", "Freeman"
    ]
  },
  {
    date: "May 2021 – Sept 2021",
    year: "2021-2021",
    company: "Myplanet",
    role: "Software Developer",
    project: "Data Consolidation",
    responsibilities: [
      "• Developed reporting capabilities for internal and external users at Think Unlimited.",
      "• Designed and implemented an ETL architecture with a team of 4, consolidating data from marketing APIs for efficient access via a GraphQL API layer for data visualization.",
      "• Contributed to Agile processes including sprint planning, daily standups, testing, retrospectives, and reviews."
    ],
    technologies: [
      "Python", "Flask", "Graphene", "GraphQL", "SQLite", "PostgreSQL", "AWS Lambda", "Asyncio", "GitHub", "Jira"
    ]
  },
  {
    date: "June 2020 – May 2021",
    year: "2020-2021",
    company: "Finneo",
    role: "Software Engineer",
    project: "Commercial Real Estate Application",
    responsibilities: [
      "• Maintained architecture of a MERN-based Real Estate application, ensuring a seamless user experience and managing RESTful API endpoints.",
      "• Developed dynamic dashboards using user data and integrated third-party real-estate APIs (CHERRE, YARDI, MRI) for up-to-date US/Canada market data.",
      "• Integrated SendGrid email system, improving user engagement and retention on the platform.",
      "• Collaborated in Agile to design workflows, user journeys, and develop new features.",
      "• Implemented CI/CD pipelines on Bitbucket Cloud, reducing deployment times."
    ],
    technologies: [
      "React.js", "Node.js", "Express.js", "MongoDB", "PostgreSQL", "Ant Design", "Redux", "Redux Sagas", "Feathers.js", 
      "AWS", "Bitbucket", "DigitalOcean", "Jira", "Asana", "SendGrid", "Google Maps", "Chart.js", "Canvas.js", "jsPDF"
    ]
  },
  {
    date: "May 2020 – Apr 2022",
    year: "2020-2022",
    company: "University of Toronto",
    role: "Assistant Instructor",
    responsibilities: [
      "• Mentored aspiring Full Stack Web Developers, teaching modern technologies like HTML, CSS, JavaScript, React.js, Node.js, Express.js, Python, Django, and more.",
      "• Led tutorials and code reviews, graded assignments, and provided constructive feedback to students."
    ]
  },
  {
    date: "May 2020 – July 2023",
    year: "2020-2023",
    company: "Springboard",
    role: "Software Engineering Mentor",
    responsibilities: [
      "• Mentored aspiring Web Developers 1:1, addressing technical queries, providing feedback, and guiding them in mastering core software engineering concepts."
    ]
  },
  {
    date: "Jan 2017 – Mar 2019",
    year: "2017-2019",
    company: "Bank Muscat",
    role: "Sr. Database Administrator",
    responsibilities: [
      "• Managed 3 production and 9 non-production databases, ensuring compliance with banking regulations.",
      "• Ensured database integrity across applications, networks, and external payment gateways."
    ],
    technologies: [
      "Oracle Databases", "RAC", "Enterprise Manager", "PRTG", "HP-UX", "Server 2012"
    ]
  },
  {
    date: "Jul 2014 – Jan 2017",
    year: "2014-2017",
    company: "Progress Ways",
    role: "Oracle Consultant",
    responsibilities: [
      "• Integrated AVDF with EBS to log application-level changes on standalone servers.",
      "• Automated database performance monitoring via Oracle Enterprise Manager.",
      "• Implemented DBV for enhanced security and prepared Separation of Duty Matrix for multiple environments.",
      "• Led EBS upgrade from 12.1 to 12.2, reducing production downtime to 45 hours with a team of five."
    ],
    technologies: [
      "Solaris 10", "Oracle 11g RAC", "Oracle EBS R12.1.2", "AVDF 12.1", "Oracle 11gR2", "DBV 11gR2", 
      "Enterprise Manager 12c", "Fusion Middleware", "WebLogic Server 10.3.6", "UNIX"
    ]
  },
  {
    date: "Jan 2013 – Jun 2014",
    year: "2013-2014",
    company: "Various Clients",
    role: "Oracle Database Administrator",
    responsibilities: [
      "• Upgraded databases from Oracle 9i/10g to 11gR2/12c with High Availability (RAC).",
      "• Configured DR sites with Physical Standby for business continuity."
    ],
    technologies: [
      "Linux", "HP-UX 11.31", "Oracle 11g RAC", "Oracle R12.1.2"
    ]
  }
];

export default workExperience;
  