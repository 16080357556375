import React from 'react';
import styled from 'styled-components';
import PText from './PText';

const AboutItemStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  /* gap: 5rem; */
  margin-top: 3rem;
  .title {
    font-size: 2.4rem;
  }
  .years-sub-title {
    font-size:1.2rem;
    padding-left:15px;
  }
  .items {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    position: absolute;
    left: 45rem;
  }
  .item {
    background-color: var(--deep-dark);
    padding: 1rem;
    border-radius: 8px;
  }
  .left {
    flex: 3;
  }
  .right {
    flex: 2;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    .items {
      position: initial;
      gap: 1rem;
    }
    .title {
      font-size: 2rem;
    }
  }
`;

export default function AboutInfoItem({
  title = 'Title',
  items = ['HTML', 'CSS'],
  span = ''
}) {
  return (
    <>
    <AboutItemStyles>
      <h1 className="title left">{title}
      {
        span 
        ? 
        <span className="years-sub-title">({span})</span> 
        : 
        ""
      }
      </h1>
      
      <div className="items right">
        {items.map((item, index) => (
          <div className="item" key={index}>
            <PText>{item}</PText>
          </div>
        ))}
        
      </div>
      
    </AboutItemStyles>
    </>
  );
}
