import React, { useState, useEffect } from 'react';
import Dino from 'react-chrome-dino';

export default function PageNotFound() {
  const [isGameOver, setIsGameOver] = useState(false);
  const [score, setScore] = useState(0); // Track the current score
  const [highScore, setHighScore] = useState(localStorage.getItem('highScore') || 0); // Retrieve the high score from localStorage
  const [showInstructions, setShowInstructions] = useState(false); // Toggle instructions visibility

  // This function will be called when the game ends
  const handleGameOver = (finalScore) => {
    setScore(finalScore);
    setIsGameOver(true);

    // Update high score if the current score is higher
    if (finalScore > highScore) {
      setHighScore(finalScore);
      localStorage.setItem('highScore', finalScore); // Save the new high score to localStorage
    }
  };

  // Reset game function
  const resetGame = () => {
    setIsGameOver(false);
    setScore(0); // Reset the score
  };

  // Toggle instructions visibility
  const toggleInstructions = () => {
    setShowInstructions(!showInstructions);
  };

  // Handle key press to prevent page scroll
  const handleKeyPress = (event) => {
    if (event.keyCode === 32 || event.keyCode === 38) { // Spacebar or Up Arrow
      event.preventDefault(); // Prevent page scrolling
    }
  };

  // Add event listener when component mounts
  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    // Cleanup event listener when component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <div className="not-found">
      <h1>404 - Page Not Found</h1>
      <h2>Oops! Looks like you're lost!</h2>
      <p>We couldn't find the page you were looking for.</p>
      <p>But hey, while you're here... why not play the famous T-Rex game?</p>

      {/* T-Rex Dino Game Container */}
      <div id="game-container" style={{ marginBottom: '20px' }}>
        <Dino onGameOver={handleGameOver} setScore={setScore} />
      </div>

      {isGameOver && (
        <div className="game-over">
          <h2>Game Over!</h2>
          <p>Your Score: {score}</p>
          <p>High Score: {highScore}</p>
          <button onClick={resetGame}>Play Again</button>
        </div>
      )}

      {/* Instructions Section */}
      {showInstructions && (
        <div className="instructions">
          <h4>How to Play:</h4>
          <ul>
            <li>Press the <strong>spacebar</strong> or <strong>up arrow key</strong> to make the Dino jump over obstacles.</li>
            <li>Try to avoid hitting the obstacles coming your way!</li>
            <li>When you collide with an obstacle, the game will end, and you can try again.</li>
          </ul>
          <button onClick={toggleInstructions}>Hide Instructions</button>
        </div>
      )}

      {/* Toggle Instructions Button */}
      {!showInstructions && (
        <button onClick={toggleInstructions}>Show Instructions</button>
      )}
    </div>
  );
}
