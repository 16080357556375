import React from 'react';
import styled from 'styled-components';
import PText from '../components/PText';
import AboutImg from '../assets/images/ninja.png';
import AboutInfoTech from '../components/AboutinfoTech';
import AboutInfoItem from '../components/AboutInfoItem';
import ContactBanner from '../components/ContactBanner';
// import CV from '../assets/data/CV.pdf'

const AboutPageStyles = styled.div`
  padding: 20rem 0 10rem 0;

  .top-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
  .intro-left {
    flex: 5;
  }
  .left {
    flex: 1;
  }
  .right {
    flex: 2;
  }
  .about__subheading {
    font-size: 2.2rem;
    margin-bottom: 2rem;
    span {
      background-color: var(--deep-dark);
      padding: 0.5rem;
      border-radius: 8px;
    }
  }
  .about__heading {
    font-size: 3.6rem;
    margin-bottom: 3rem;
  }
  .about__info {
    margin-bottom: 4rem;
    .para {
      max-width: 100%;
      text-align: justify;
      padding-right : 20px;
    }
  }
  .right {
    img {
      border: 2px solid var(--gray-1);
    }
  }
  .about__info__items {
    margin-top: 10rem;
  }
  .about__info__item {
    margin-bottom: 10rem;
  }
  .about__info__heading {
    font-size: 3.6rem;
    text-transform: uppercase;
  }
  @media only screen and (max-width: 768px) {
    padding: 10rem 0;
    .top-section {
      flex-direction: column;
      gap: 5rem;
    }
    .about__subheading {
      font-size: 2.5rem;
    }
    .about__heading {
      font-size: 2.5rem;
    }
    .about__info__heading {
      font-size: 3rem;
    }
    .about-display-image {
      display: none;
    }
  }
`;

export default function About() {
  return (
    <>
      <AboutPageStyles>
        <div className="container">
          <div className="top-section">
            <div className="intro-left">
              <p className="about__subheading">
                Hi, I am <span>Anas Qazi</span>
              </p>
              <h2 className="about__heading">A Software Engineer</h2>
              <div className="about__info">
              
                <PText>
                  With over 10 years of experience across diverse domains within the IT industry, 
                  I am a versatile Full Stack Web Developer and Software Engineer who thrives on 
                  solving complex problems and delivering high-performance, scalable solutions. 
                  I specialize in leveraging modern frameworks, cloud technologies, and 
                  innovative software architectures to deliver tailored digital solutions.
                </PText>
                <PText>
                  I am proficient in a wide range of technologies, including JavaScript (ES6+), 
                  MERN Stack, AWS, GCP, and Azure.
                </PText>
                <PText>
                  My experience in various roles from Oracle Consultant and Database Administrator to Full Stack Developer 
                  has equipped me with a cross-domain perspective that drives innovation and ensures solutions 
                  align with business goals.
                </PText>
                <PText>
                  I am deeply committed to writing clean, maintainable code and continuously evolving my skills 
                  to meet the demands of rapidly changing technologies. Collaboration is at the heart of my work, 
                  whether it’s partnering with clients, mentoring fellow developers, or working with cross-functional teams 
                  to build accessible, user-friendly solutions.
                </PText>
                <PText>
                  My vision is to harness technology to solve complex problems, innovate, and make a positive impact. 
                  I am always excited about new challenges and opportunities to drive digital transformation.
                </PText>
              
              </div>

              <a 
              href="/resume" 
              target='_blank' 
              rel="noopener noreferrer">
                <button type="button" className="btn btn-lg btn-outline-primary">Resume</button>

              </a>

            </div>
            <div className="right about-display-image">
              <img src={AboutImg} alt="me" />
            </div>
          </div>
          <div className="about__info__items">

            {/* Education Section  */}
            <div className="about__info__item">
              <h1 className="about__info__heading">Education</h1>

              <AboutInfoItem
                title="Full Stack Web Development"
                items={['2020 - University of Toronto, Canada']}
              />
              <AboutInfoItem
                title="Bachelor in Computer Engineering"
                items={['2013 - University of Mumbai, India']}
              />
            </div>

            {/* My Skillset Section  */}
            <div className="about__info__item">
              <h1 className="about__info__heading">My Skills</h1>


              {/* Languages Section */}
              <AboutInfoTech
                title="Languages"
                items={['JavaScript (ES6+)', 'HTML', 'CSS']}
              />
              <br />

              {/* Frameworks Section */}
              <AboutInfoTech
                title="Frameworks"
                items={['React', 'Angular', 'Vue', 'Next.js', 'Flask', 'Django']}
              />
              <br />

              {/* Libraries Section */}
              <AboutInfoTech
                title="Libraries"
                items={[
                  'Express.js', 'Node.js', 'GraphQL', 'Redux', 'jQuery', 'Bootstrap', 'Materialize', 
                  'Axios', 'AJAX', 'DOM manipulation', 'Git', 'GitHub', 'Bitbucket', 'NPM', 'YARN', 
                  'Pip', 'pipenv', 'Asyncio', 'Heroku', 'Webpack', 'CI/CD Pipelines', 'Travis', 'CircleCI', 
                  'Jenkins', 'GitHub Actions', 'GitLab CI'
                ]}
              />
              <br />

              {/* Cloud & DevOps (AWS) Section */}
              <AboutInfoTech
                title="Cloud & DevOps (AWS)"
                items={[
                  'EC2', 'Fargate', 'ECS', 'Lambda', 'S3', 'RDS', 'Aurora', 'ElastiCache', 'VPC', 
                  'ELB', 'IAM', 'CodeCommit', 'CodePipeline', 'CodeDeploy', 'CodeBuild', 'Docker', 
                  'Kubernetes', 'AWS CloudFormation'
                ]}
              />
              <br />

              {/* Databases & Data Management Section */}
              <AboutInfoTech
                title="Databases & Data Management"
                items={[
                  'MySQL', 'PostgreSQL', 'SQLite', 'SQL Server', 'MongoDB', 'Sequelize', 
                  'Mongoose', 'Amazon ElastiCache'
                ]}
              />
              <br />

              {/* Oracle Section */}
              <AboutInfoTech
                title="Oracle"
                items={[
                  'Real Application Cluster', 'Data Guard', 'E-Business Suite', 'Enterprise Manager', 
                  'ASM', 'SAN', 'RMAN'
                ]}
              />
              <br />

              {/* Version Control & Package Management Section */}
              <AboutInfoTech
                title="Version Control & Package Management"
                items={[
                  'Git', 'GitHub', 'Bitbucket', 'NPM', 'YARN', 'Pip', 'pipenv'
                ]}
              />
              <br />

              {/* Operating Systems Section */}
              <AboutInfoTech
                title="Operating Systems"
                items={[
                  'Linux (Ubuntu, CentOS)', 'Unix', 'HP-UX', 'Solaris', 'macOS', 'Windows'
                ]}
              />
              <br />

              {/* Virtualization & Containers Section */}
              <AboutInfoTech
                title="Virtualization & Containers"
                items={[
                  'Virtual Machines (VMs)', 'Docker', 'Kubernetes'
                ]}
              />
              <br />

              {/* Server Management Section */}
              <AboutInfoTech
                title="Server Management"
                items={[
                  'Apache', 'Nginx', 'Tomcat'
                ]}
              />
              <br />

              {/* Liferay Section */}
              <AboutInfoTech
                title="Liferay"
                items={[
                  'Portal', 'Liferay DXP', 'Themes', 'Service Builder', 'Integration with external systems', 
                  'APIs', 'Liferay Analytics Cloud', 'Roles', 'Permissions', 'Authentication', 
                  'Liferay IDE', 'Developer Studio', 'Workspace'
                ]}
              />
              <br />

              {/* Other Tools & Technologies Section */}
              <AboutInfoTech
                title="Other Tools & Technologies"
                items={[
                  'AWS SNS', 'SQS', 'Postman', 'Swagger', 'Data Guard', 'E-Business Suite', 
                  'Enterprise Manager', 'ASM', 'SAN', 'RMAN'
                ]}
              />
              
            </div>

            {/* Professional Experience Section  */}
            <div className="about__info__item">
              <h1 className="about__info__heading">Professional Experience</h1>

              <AboutInfoItem
                title="Sept 2021 – Present"
                // span="3 yrs, 3 months"
                items={['Software Engineer @ Veriday']}
                
              />
              <AboutInfoItem
                title="May 2020 – Apr 2022"
                span="2 yrs"
                items={['Instructor @ University of Toronto']}
              />
              <AboutInfoItem
                title="May 2020 – Jul 2023"
                span="3 yrs, 3 months"
                items={['Software Engineering Mentor @ Springboard']}
              />
              <AboutInfoItem
                title="May 2021 – Sept 2021"
                span="5 months"
                items={['Software Developer @ Myplanet']}
              />
              <AboutInfoItem
                title="June 2020 – May 2021"
                span="11 months"
                items={['Software Engineer @ Finneo']}
              />
              <AboutInfoItem
                title="Jan 2017 – Mar 2019"
                span="2 yrs, 3 months"
                items={['Database Administrator @ Bank Muscat']}
              />
              <AboutInfoItem
                title="Jul 2014 - Jan 2017"
                span="2 yrs, 7 months"
                items={['Oracle Consultant @ Progress Ways']}
              />
            </div>

            
          </div>
        </div>
        <ContactBanner />
      </AboutPageStyles>
    </>
  );
}
