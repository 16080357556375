import React from 'react';
import styled from 'styled-components';

import Timeline from '../components/Timeline';

const ResumePageStyles = styled.div`
  /* General Styles */
  .card {
    background-color: #363636;
  }

  i, .fab, .fas {
    padding: 5px;
    transition: color 0.3s ease;  /* Smooth transition for hover effect */
    color: var(--gray-1); /* Default icon color */
  }

  .btn {
    margin: 5px;
  }

  a {
    color: black;
    text-decoration: none; /* Remove underline by default */
  }

  .main {
    margin: auto;
    padding-top: 100px;
  }

  .center {
    margin: auto;
    width: 100%;
    text-align: center;
  }

  .padding {
    margin: auto;
    width: 80%;
  }

  /* Header Styling */
  #headerSection {
    text-align: center;
    margin-bottom: 20px;
  }

  #headerSection h1 {
  font-size: 5rem;
  }

  #headerDetails {
    font-size: 1rem;
    color: #666;
  }

  #headerDetails a {
    color: #007bff;
  }

  /* Header details text styles */
  #headerDetails {
    color: var(--gray-1);
    font-weight: 400;
    font-size: 1.5rem;
    margin-top: 10px;
  }

  #headerDetails a {
    color: inherit;  /* Inherit color from parent */
  }

  #headerDetails a:hover {
    color: #0073e6;  /* Change color when hovering over links */
        text-decoration: none;

  }
  
  #headerDetails:hover {
    color: #0073e6;  /* Change color when hovering over links */

  }

  /* Icon hover effect */
  .fab:hover, .fas:hover {
    color: #0073e6;  /* Change to blue color on hover */
  }

  .resume-page-text {
    font-size : 1.4rem;
  }

  /* Table Styling for Technical Skills */
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  table td, table th {
    padding: 8px 10px;
    text-align: left;
    border-bottom: 1px solid #ddd; /* Light border between rows */
  }

  table td {
    color: var(--gray-1);
    font-size: 1.2rem;
  }

  table th {
    background-color: #f0f0f0;
    font-weight: bold;
    color: #333;
  }

  table tr:nth-child(even) {
    // background-color: #f9f9f9; /* Alternate row background color */
  }

  table tr:hover {
    // background-color: var(--highlight-blue); /* Highlight row on hover */
    cursor: pointer;
    color: red;
  }
`;


export default function Resume() {
  return (
    <>
      <ResumePageStyles>
        <div className='main'>
          <div className='float-right position-sticky' style={{ marginRight: '20px' }}>
            <a href="/resume" target='_blank' rel="noopener noreferrer">
              <button type="button" className="btn btn-lg btn-outline-primary">Download CV</button>
            </a>
          </div>

          {/* <!-- HEADER INFORMATION SECTION  --> */}
          <div className="padding">
            <div className="container-fluid" id="headerSection">
              <div className="row">
                <div className="col text-center">
                  <h1>Anas Qazi</h1>
                  <a href="https://goo.gl/maps/Pow2ANRwSmM8dNJC6" target="_blank" rel="noopener noreferrer"><span id="headerDetails">Toronto, Canada</span></a>&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;
                  <a href="tel:647-581-7770" ><span id="headerDetails">+1-647-581-7770</span></a>&nbsp;&nbsp;&nbsp; |
                  &nbsp;&nbsp;&nbsp;
                  <a href="mailto:ianasqazi@gmail.com" rel="noopener noreferrer"><span id="headerDetails">ianasqazi@gmail.com</span></a>
                  <br />
                  <a href="https://github.com/ianasqazi" target="_blank" rel="noopener noreferrer"><i className="fab fa-github fa-4x"></i></a>
                  <a href="https://ianasqazi.ca/" target="_blank" rel="noopener noreferrer"><i className="fas fa-code fa-4x"></i></a>
                  <a href="https://www.linkedin.com/in/anasqazi/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin fa-4x"></i></a>
                  <br />
                </div>
                <br />
              </div>
              <hr color={'#BCB4B4'}/>
            </div>
          </div>

          {/* <!-- OBJECTIVE SECTION  --> */}
          <div className="padding">
            <div className="container-fluid resume-page-text" id="objectiveSection">
              <div className="row">
                <div className="col">
                  <ul>
                    <li>• Versatile Full Stack Web Developer with 10+ years of experience designing, developing, and deploying production-grade applications across diverse IT domains.</li>
                    <li>• Currently working as a Software Developer at Veriday for the past three years, delivering high-performance digital solutions tailored to client needs.</li>
                    <li>• Graduate of the Full Stack Web Development program at the University of Toronto, with expertise in modern frameworks, cloud technologies, and scalable architectures.</li>
                    <li>• Previous experience as an Oracle Consultant and Database Administrator, with strong skills in database management, optimization, and enterprise solutions.</li>
                    <li>• Results-driven, collaborative professional with a strong track record of achieving project goals efficiently and effectively, while fostering teamwork and aligning with organizational objectives.</li>
                    <li>• Passionate about writing clean, maintainable code and continuously learning cutting-edge web technologies to drive innovation.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <br />

          {/* <!-- TECHNICAL SKILLS SECTION  --> */}
          <div className="padding" id="technical-skills-section">
            <div className="container-fluid resume-page-text">
              <div className="row">
                <div className="col">
                  <h3 className="about__info__heading">TECHNICAL SKILLS</h3>
                </div>
              </div>

              <table className="table">
                <tbody>
                  {/* Languages Section */}
                  <tr style={{ borderBottom: '1px solid var(--gray-1)' }}>
                    <td style={{ fontWeight: 'bold' }}>Languages</td>
                    <td>JavaScript (ES6+), HTML, CSS</td>
                  </tr>
                  
                  {/* Frameworks Section */}
                  <tr style={{ borderBottom: '1px solid var(--gray-1)' }}>
                    <td style={{ fontWeight: 'bold' }}>Frameworks</td>
                    <td>React, Angular, Vue, Next.js, Flask, Django</td>
                  </tr>

                  {/* Libraries Section */}
                  <tr style={{ borderBottom: '1px solid var(--gray-1)' }}>
                    <td style={{ fontWeight: 'bold' }}>Libraries</td>
                    <td>Express.js, Node.js, GraphQL, Redux, jQuery, Bootstrap, Materialize, Axios, AJAX, DOM manipulation, Git, GitHub, Bitbucket, NPM, YARN, Pip, pipenv, Asyncio, Heroku, Webpack, CI/CD Pipelines, Travis, CircleCI, Jenkins, GitHub Actions, GitLab CI</td>
                  </tr>

                  {/* Cloud & DevOps (AWS) Section */}
                  <tr style={{ borderBottom: '1px solid var(--gray-1)' }}>
                    <td style={{ fontWeight: 'bold' }}>Cloud & DevOps (AWS)</td>
                    <td>EC2, Fargate, ECS, Lambda, S3, RDS, Aurora, ElastiCache, VPC, ELB, IAM, CodeCommit, CodePipeline, CodeDeploy, CodeBuild, Docker, Kubernetes, AWS CloudFormation</td>
                  </tr>

                  {/* Databases & Data Management Section */}
                  <tr style={{ borderBottom: '1px solid var(--gray-1)' }}>
                    <td style={{ fontWeight: 'bold' }}>Databases & Data Management</td>
                    <td>MySQL, PostgreSQL, SQLite, SQL Server, MongoDB, Sequelize, Mongoose, Amazon ElastiCache</td>
                  </tr>

                  {/* Oracle Section */}
                  <tr style={{ borderBottom: '1px solid var(--gray-1)' }}>
                    <td style={{ fontWeight: 'bold' }}>Oracle</td>
                    <td>Real Application Cluster, Data Guard, E-Business Suite, Enterprise Manager, ASM, SAN, RMAN</td>
                  </tr>

                  {/* Version Control & Package Management Section */}
                  <tr style={{ borderBottom: '1px solid var(--gray-1)' }}>
                    <td style={{ fontWeight: 'bold' }}>Version Control & Package Management</td>
                    <td>Git, GitHub, Bitbucket, NPM, YARN, Pip, pipenv</td>
                  </tr>

                  {/* Operating Systems Section */}
                  <tr style={{ borderBottom: '1px solid var(--gray-1)' }}>
                    <td style={{ fontWeight: 'bold' }}>Operating Systems</td>
                    <td>Linux (Ubuntu, CentOS), Unix, HP-UX, Solaris, macOS, Windows</td>
                  </tr>

                  {/* Virtualization & Containers Section */}
                  <tr style={{ borderBottom: '1px solid var(--gray-1)' }}>
                    <td style={{ fontWeight: 'bold' }}>Virtualization & Containers</td>
                    <td>Virtual Machines (VMs), Docker, Kubernetes</td>
                  </tr>

                  {/* Server Management Section */}
                  <tr style={{ borderBottom: '1px solid var(--gray-1)' }}>
                    <td style={{ fontWeight: 'bold' }}>Server Management</td>
                    <td>Apache, Nginx, Tomcat</td>
                  </tr>

                  {/* Liferay Section */}
                  <tr style={{ borderBottom: '1px solid var(--gray-1)' }}>
                    <td style={{ fontWeight: 'bold' }}>Liferay</td>
                    <td>Portal, Liferay DXP, Themes, Service Builder, Integration with external systems, APIs, Liferay Analytics Cloud, Roles, Permissions, Authentication, Liferay IDE, Developer Studio, Workspace</td>
                  </tr>

                  {/* Other Tools & Technologies Section */}
                  <tr style={{ borderBottom: '1px solid var(--gray-1)' }}>
                    <td style={{ fontWeight: 'bold' }}>Other Tools & Technologies</td>
                    <td>AWS SNS, SQS, Postman, Swagger, Data Guard, E-Business Suite, Enterprise Manager, ASM, SAN, RMAN</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <br />

          {/* <!-- PROFESSIONAL EXPERIENCE SECTION  --> */}
          <Timeline />

          <br />

          {/* <!-- EDUCATION SECTION  --> */}
          <div className="padding">
                <div className="container-fluid resume-page-text" id="timelineSection">

                    <div className="row">
                        <div className="col">
                            <h3>EDUCATION</h3>
                        </div>
                    </div>

                    <br />

                    <div className="row">

                        <div className="col-3">
                            <h4>University of Toronto, Canada</h4>
                        </div>
                        <div className="col-7">
                            <span>Full Stack Web Development</span>
                        </div>
                        <div className="col-2">
                            <span>2020</span>
                        </div>
                    </div>

                    <br />

                    <div className="row">
                        <div className="col-3">
                            <h4>University of Mumbai, India</h4>
                        </div>
                        <div className="col-7">
                            <span>Bachelor of Engineering - Computer Science</span>
                        </div>
                        <div className="col-2">
                            <span>2013</span>
                        </div>
                    </div>

                </div>
            </div>

            <br />

          {/* <!-- CERTIFICATIONS SECTION  --> */}
          <div className="padding">
            <div className="container-fluid" id="certificationSection">
              <div className="row">
                <div className="col">
                  {/* <h5 className="about__info__heading">CERTIFICATIONS</h5> */}
                </div>
              </div>

              <ul>
                {/* <li>• AWS Certified Solutions Architect – Associate (2023)</li>
                <li>• AWS Certified Developer – Associate (2023)</li>
                <li>• Full Stack Web Development – University of Toronto (2022)</li> */}
              </ul>
            </div>
          </div>

          <br />
        </div>
      </ResumePageStyles>
    </>
  );
}
