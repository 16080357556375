import { v4 as uuidv4 } from 'uuid';
import CovidImg from '../images/covid_dashboard.png';
import JobsDragonsImg from '../images/jobsanddragons.png';
import SpotifyImg from '../images/spotify-native3.png';
import GoogleBooksImg from '../images/gbooksreact.png';
import EmpDirImg from '../images/react-employee-directory.png';
import PWABudgetImg from '../images/pwaBudget.png';
import FitnessTrackerImg from '../images/workout_tracker.png';
import BurgerizerImg from '../images/burger_screenshot.png';
import HRTrakerImg from '../images/HR_emp_ss.png';
import NoteWorthyImg from '../images/NoteWorthy.png';
import ClimaImg from '../images/clima_ss.png';
import DayPlannerImg from '../images/dayplanner_ss.png';
import JSQuizImg from '../images/jsquiz_ss.png';
import PwdGenImg from '../images/password_generator_ss.png';


const projects = [
  {
    id: uuidv4(),
    name: 'Covid-19 DASHBOARD',
    desc:
      `A Corona virus Dashboard with MAP showing up-to-date statistics 
      of increase of corona virus around the world.Hover over the countries 
      to see additional details for each country in tooltips.`,
    img: CovidImg,
    appLink: 'https://codesandbox.io/s/covid19-dashboard-kmv7r',
    githubLink: 'https://github.com/ianasqazi/covid-dashboard',
    technologiesUsed: `REACT, REACT-ROUTER-DOM, REACTSTRAP, NODE.js, REACTLOADINGG,
    MAPBOX-GL, AXIOS, SWR, COUNTRY-CODE-LOOKUP, COMMA-NUMBER, CORONA Virus Info API's`,
  },
  {
    id: uuidv4(),
    name: 'Jobs & Dragons',
    desc:
      `Full stack job search application built in
      a role-playing game (RPG)
      format to make job hunt
      exciting. Each player/job applicant will receive 
      series of quests to complete.A player
      has to successfully complete
      all the levels in order to be eligible to apply jobs. `,
    img: JobsDragonsImg,
    appLink: 'https://jobs-and-dragons.herokuapp.com/',
    githubLink: 'https://github.com/Winyumi/jobs-and-dragons',
    technologiesUsed: `REACT, REACT-HOOKS, REACT-ROUTER, CONTEXT-API, EXPRESS.js,
    NODE.js, MATERIALIZE CSS, MONGO DB, MONGOOSE, AUTH0, GITHUB API and ADZUNA API`,
  },
  {
    id: uuidv4(),
    name: 'CLIMA - WEATHER',
    desc:
      `Built an application to access current weather forcast of over 200,000 cities using
      vanilla JavaScript and jQuery.`,
    img: ClimaImg,
    appLink: 'https://ianasqazi.github.io/Weather-App/',
    githubLink: 'https://github.com/ianasqazi/Weather-App',
    technologiesUsed: `JAVASCRIPT, jQUERY, HTML, CSS, AJAX and OPEN WEATHER API`,
  },
  {
    id: uuidv4(),
    name: 'Spotify Player',
    desc:
      `Motivated to understand the building of a
      native mobile app which directly interact with the
      API without depending on middleware such as plugins and WebViews.`,
    img: SpotifyImg,
    appLink: 'https://expo.io/@ianasqazi/spotify',
    githubLink: 'https://github.com/ianasqazi/spotify',
    technologiesUsed: `REACT, REACT-NATIVE, REACT-DOM, EXPO and SPOTIFY API`,
  },
  {
    id: uuidv4(),
    name: 'Google Books',
    desc:
      `Full stack book search application where
      users can search and buy e-books directly from the
      Google books website. Users can save their favorites books if they wish to purchase
      later`,
    img: GoogleBooksImg,
    appLink: 'https://gbooksreact.herokuapp.com/',
    githubLink: 'https://github.com/ianasqazi/gbooks-react',
    technologiesUsed: `REACT, REACT-ROUTER-DOM, EXPRESS.js, NODE.js, MATERIALIZE CSS,
    MONGO DB, MONGOOSE, AXIOS and GOOGLE BOOKS API`,
  },
  {
    id: uuidv4(),
    name: 'Employee Directory',
    desc:
      `Developed an application to manage contact
      details of employees in a company. User can
      search using filters like Name, Phone, email, DOB etc`,
    img: EmpDirImg,
    appLink: 'https://emp-dir-react.herokuapp.com/',
    githubLink: 'https://github.com/ianasqazi/emp-dir-react',
    technologiesUsed: `REACT, REACT-DOM, NODE.js, BOOTSTRAP, AXIOS and RANDOM USER API`,
  },
  {
    id: uuidv4(),
    name: 'Progressive Web App - Budget Tracker',
    desc:
      `This project displays the functionality of
      caching data to be used in offline mode. The application has his own transaction wallet
      which he can use online as well as offline if user does not have internet. And the
      transaction history adds it to the total when back online.`,
    img: PWABudgetImg,
    appLink: 'https://mysterious-tundra-39658.herokuapp.com/',
    githubLink: 'https://github.com/ianasqazi/PWA-Budged-Tracker',
    technologiesUsed: `EXPRESS.js, NODE.js, CHART.js, BOOTSTRAP, LITE-SERVER, MONGOOSE`,
  },
  {
    id: uuidv4(),
    name: 'Fitness Tracker DASHBOARD',
    desc:
      `Manage your workouts by adding new workouts
      or routines and view results in different charts and graphs.`,
    img: FitnessTrackerImg,
    appLink: 'https://intense-shore-05475.herokuapp.com/',
    githubLink: 'https://github.com/ianasqazi/WorkoutTracker',
    technologiesUsed: `EXPRESS.js, MONGO DB, MONGOOSE, MORGAN, PATH and BODY-PARSER`,
  },
  {
    id: uuidv4(),
    name: 'BURGERIZER - HANDLEBARS',
    desc:
      `This application is a sample of wish list of burgers I want to have and once completed I
      checked it as devoured.`,
    img: BurgerizerImg,
    appLink: 'https://burgerizer.herokuapp.com/',
    githubLink: 'https://github.com/ianasqazi/Burger-Handlebars',
    technologiesUsed: `EXPRESS, EXPRESS-HANDLEBARS and MYSQL`,
  },
  {
    id: uuidv4(),
    name: 'HR - EMPLOYEE TRACKER',
    desc:
      `Built a Employee tracker application using vanilla JavaScript in Command Line Interface
      and some libraries which are helpful in CLI. Human resources department uses
      applications like these to ADD/UPDATE/DELETE employees and their
      ROLES/DEPARTMENTS/SALARIES etc.`,
    img: HRTrakerImg,
    appLink: 'https://repl.it/github/ianasqazi/HR-Employee-Tracker',
    githubLink: 'https://github.com/ianasqazi/HR-Employee-Tracker',
    technologiesUsed: `NODE.js, JAVASCRIPT, HTML, CSS, MYSQL, INQUIRER,
    CONSOLE-TABLE-PRINTER and ASCII-ART`,
  },
  {
    id: uuidv4(),
    name: 'NOTE - WORTHY',
    desc:
      `An application that can be used to write, save, and delete notes.
      This application will use an express backend and save and retrieve note data from a JSON
      file.`,
    img: NoteWorthyImg,
    appLink: 'https://note-worty.herokuapp.com/',
    githubLink: 'https://github.com/ianasqazi/Note-Worthy',
    technologiesUsed: `EXPRESS.js, NODE.js, NODEMON and SHORTID`,
  },
  {
    id: uuidv4(),
    name: 'Day Planner',
    desc:
      `Simple Calendar Application that allows the user to save events for each hour of the
      day which is saved in browser's Local Storage.`,
    img: DayPlannerImg,
    appLink: 'https://ianasqazi.github.io/Day-Planner/',
    githubLink: 'https://github.com/ianasqazi/Day-Planner',
    technologiesUsed: `JAVASCRIPT, jQUERY, BOOTSTRAP and MOMENT.js`,
  },
  {
    id: uuidv4(),
    name: 'JS CODE QUIZ',
    desc:
      `A simple questionnaire application which will give you score based on how fast you
      answer the questions.`,
    img: JSQuizImg,
    appLink: 'https://ianasqazi.github.io/JS-Quiz/',
    githubLink: 'https://github.com/ianasqazi/JS-Quiz/',
    technologiesUsed: `NODE.js, INQUIRER, OPEN, JEST`,
  },
  {
    id: uuidv4(),
    name: 'Random Password Generator',
    desc:
      `Generate a random password of variable length and complexity by providing input by user.`,
    img: PwdGenImg,
    appLink: 'https://ianasqazi.github.io/Password-Generator/',
    githubLink: 'https://github.com/ianasqazi/Password-Generator',
    technologiesUsed: `JAVASCRIPT, HTML, CSS and BOOTSTRAP`,
  },
];

export default projects;
