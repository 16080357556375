import React from 'react';
import styled from 'styled-components';
import FooterCol from './FooterCol';

const FooterStyle = styled.div`
  background-color: var(--deep-dark);
    padding: 8rem 0 1rem;
    color: var(--gray-1);

  .container {
    display: flex;
    gap: 3rem;
  }
  
  .footer__col1 {
    flex: 2;
  }
  .footer__col2,
  .footer__col3,
  .footer__col4 {
    flex: 1;
  }
  .footer__col1__title {
    font-size: 3.5rem;
    margin-bottom: 1rem;
  }
  .copyright {
    background-color: var(--dark-bg);
    text-align: left;
    padding: 1rem 0;
    margin-top: 5rem;
    .para {
      margin-left: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    .container {
      flex-direction: column;
      gap: 0rem;
      & > div {
        margin-top: 5rem;
      }
    }
    .footer__col1 .para {
      max-width: 100%;
    }
    .copyright {
      .container {
        div {
          margin-top: 0;
        }
      }
    }
  }
`;

export default function Footer() {

  let newDate = new Date()
  let currentYear = newDate.getFullYear();

  return (
    <FooterStyle>
      <div className="container">
        <div className="footer__col1">
          <h1 className="footer__col1__title">Anas Qazi</h1>
        </div>
        <div className="footer__col2">
          <FooterCol
            heading="Links"
            links={[
              { type: 'Link', title: 'Home', path: '/' },
              { type: 'Link', title: 'About', path: '/about' },
              { type: 'Link',  title: 'Resume', path: '/resume' },
              { type: 'Link', title: 'Projects', path: '/projects' },
              { type: 'Link',  title: 'Contact', path: '/contact' },
            ]}
          />
        </div>
        <div className="footer__col3">
          <FooterCol
            heading="Contact Info"
            links={[
              {
                title: '+1-647-581-7770',
                path: 'tel:+1-647-581-7770',
              },
              {
                title: 'ianasqazi@gmail.com',
                path: 'mailto:ianasqazi@gmail.com',
              },
              {
                title: 'Toronto, Canada',
                path: 'https://www.google.com/maps/place/Toronto,+ON/@43.717899,-79.6582408,10z/data=!3m1!4b1!4m5!3m4!1s0x89d4cb90d7c63ba5:0x323555502ab4c477!8m2!3d43.653226!4d-79.3831843?hl=en-GB',
              },
            ]}
          />
        </div>
        <div className="footer__col4">
          <FooterCol
            heading="Social Links"
            links={[
              {
                title: 'LinkedIn',
                target: "_blank",
                path: 'https://www.linkedin.com/in/anasqazi/',
              },
              {
                title: 'Github',
                target: "_blank",
                path: 'https://www.github.com/ianasqazi/',
              },
              {
                title: 'Twitter',
                target: "_blank",
                path: 'https://twitter.com/ianasqazi',
              },
              {
                title: 'Facebook',
                target: "_blank",
                path: 'https://www.facebook.com/ianasqazi',
              },
              {
                title: 'Instagram',
                target: "_blank",
                path: 'https://www.instagram.com/ianasqazi',
              },
            ]}
          />
        </div>
      </div>
      <div className="copyright">
        <div className="row container-fluid text-right" id="fixedBotton">
            <div className="col">
                <span id="footerNoteSpan"> &copy; Anas Qazi - {currentYear}</span>
            </div>

        </div>
      </div>
    </FooterStyle>
  );
}
